<template>
  <div class="list">
    <div class="lt">
      <slot name="lt"></slot>
    </div>
    <div class="mid">
      <slot name="mid"></slot>
    </div>
    <div class="rt">
      <slot name="rt"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'formList',
};
</script>

<style lang="less" scoped>
.list {
  display: flex;
  padding: 10px;
  line-height: 30px;
  border-bottom: 1px solid #ddd;

  .lt {
    width: 100px;
    font-size: 14px;
    color: #333;
  }

  .mid {
    flex: 1;
  }

  .rt {
    text-align: right;
    width: 100px;
  }
}
</style>
